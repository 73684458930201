<template>
    <div>
        <div class="vx-row ml-1 mt-3">
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Source Warehouse</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <Warehouse @selected="(val) => {
                        this.selectedSrcWarehouse = val
                    }"></Warehouse>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Transaction Date</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <Date :default="false" @selected="(val) => {
                        this.transactionDate = val
                    }"></Date>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <Table :draw="draw" :status="activeTab" :transaction-date="transactionDate"
                    :source-warehouse-id="(this.selectedSrcWarehouse) ? this.selectedSrcWarehouse.id : 0"></Table>
            </div>
        </div>
    </div>
</template>
<script>

import Warehouse from "./component/Warehouse.vue";
import Date from "./component/Date.vue";
import Table from "./component/Table.vue";
export default {
    components: {
        Warehouse,
        Date,
        Table
    },
    data() {
        return {
            activeTab: "Rejected",
            draw: 0,
            transactionDate: null,
            selectedSrcWarehouse: null,
        }
    },
    methods: {
        handleFilter() {
            this.draw++
        },
    }
}
</script>